import EasyConfetti from 'easy-confetti';
import DialogLite from 'dialog-lite';
import HamsterClicker from './utils/HamsterClicker';
import ZoomPrevention from './utils/ZoomPrevention';
import FakeTimer from './utils/FakeTimer';

const App = {
  hamsterClicker: null,
  zoomPrevention: new ZoomPrevention(),
  fakeTimer: new FakeTimer(30, '.clock'),
  easyConfetti: null,
  dialogLite: null,

  initHamsterClicker() {
    this.hamsterClicker = new HamsterClicker({
      incrementValue: 5,
      delay: 1500,
      throttleDelay: 100,
      callback: () => {
        this.initSprinkleMultiple(4, 250);

        setTimeout(() => this.dialogLite.open(), 1200);
      },
    });

    this.hamsterClicker.init();
  },

  initEasyConfetti() {
    const confettiParams = {
      particleCount: 60,
      particleSizeRange: {
        width: [6, 14],
        height: [10, 20],
      },
      initialSpeed: 25,
      gravity: 0.65,
      airResistance: 0.28,
      maxFallSpeed: 3,
      flipFrequency: 0.017,
      colors: [
        { front: '#F9E991', back: '#FFB724' },
        { front: '#F6DA52', back: '#CA8800' },
        { front: '#5056DD', back: '#1D378F' },
      ],
    };

    this.easyConfetti = new EasyConfetti(confettiParams);
    this.easyConfetti.init();
  },

  initSprinkleMultiple(times, interval) {
    Array.from({ length: times }).forEach((_, i) => {
      setTimeout(() => {
        this.easyConfetti.sprink();
      }, i * interval);
    });
  },

  initDialogLite() {
    this.dialogLite = new DialogLite({
      closingButton: false,
      closingBackdrop: false,
    });

    this.dialogLite.init();
  },

  // scheduleDialogOpen() {
  //   setTimeout(() => {
  //     this.dialogLite.open();
  //   }, 7000);
  // },

  async init() {
    this.zoomPrevention.init();
    this.fakeTimer.init();
    this.initEasyConfetti();
    this.initHamsterClicker();
    this.initDialogLite();
    // this.scheduleDialogOpen();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
