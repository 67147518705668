class ZoomPrevention {
  constructor() {
    this.lastTouchEnd = 0;
  }

  init() {
    document.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: false });
    document.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
  }

  handleTouchStart(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }

    if (event.touches.length === 1) {
      const now = (new Date()).getTime();

      if (now - this.lastTouchEnd <= 500) {
        event.preventDefault();
      }
    }
  }

  handleTouchEnd(event) {
    const now = (new Date()).getTime();

    if (now - this.lastTouchEnd <= 500) {
      event.preventDefault();
    }

    this.lastTouchEnd = now;
  }
}

export default ZoomPrevention;
