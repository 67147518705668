class HamsterClicker {
  constructor(options = {}) {
    this.clickCounter = 0;
    this.incrementValue = options.incrementValue || 10;
    this.delay = options.delay || 1000;
    this.throttleDelay = options.throttleDelay || 500;
    this.clickRate = 0;
    this.lastClickTime = 0;
    this.decreaseInterval = null;
    this.callback = options.callback || function defaultCallback() {};
    this.inactivityTimeout = null;
    this.inactivityDelay = options.inactivityDelay || 6000;

    this.hamsterElement = document.querySelector('.hamster-click__clicker');
    this.counterElement = document.querySelector('.total-clicks');
    this.clickRateElement = document.querySelector('.hamster-click');

    if (!this.hamsterElement || !this.counterElement || !this.clickRateElement) {
      throw new Error('Elements with class .hamster-click__clicker, .total-clicks, or .hamster-click are not found');
    }

    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.throttledHandlePointerDown = this.throttledHandlePointerDown.bind(this);
    this.handleInactivity = this.handleInactivity.bind(this);
  }

  init() {
    this.hamsterElement.addEventListener('pointerdown', this.throttledHandlePointerDown);
    this.startDecreaseClickRate();
    this.resetInactivityTimeout();
  }

  handlePointerDown(event) {
    if (this.clickCounter >= 100) {
      this.disableClicking();
      this.callback();
      return;
    }

    this.clickCounter += this.incrementValue;
    this.updateClickRate();

    const num = document.createElement('div');
    num.className = 'click-num';
    num.textContent = `+${this.incrementValue}`;

    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    Object.assign(num.style, {
      left: `${x}px`,
      top: `${y}px`,
      position: 'absolute',
      display: 'grid',
    });

    const targetElement = event.currentTarget;
    targetElement.appendChild(num);

    setTimeout(() => {
      if (targetElement.contains(num)) {
        targetElement.removeChild(num);
      }
    }, this.delay);

    this.counterElement.textContent = this.clickCounter;

    this.resetInactivityTimeout();

    if (this.clickCounter >= 100) {
      this.disableClicking();
      this.callback();
    }
  }

  throttledHandlePointerDown(event) {
    const now = Date.now();
    if (now - this.lastClickTime >= this.throttleDelay) {
      this.handlePointerDown(event);
      this.lastClickTime = now;
    }
  }

  updateClickRate() {
    this.clickRate = Math.min(this.clickRate + 0.1, 0.65);
    this.clickRateElement.style.setProperty('--click-rate', this.clickRate.toFixed(2));
    this.resetDecreaseInterval();
  }

  startDecreaseClickRate() {
    this.decreaseInterval = setInterval(() => {
      if (this.clickRate > 0) {
        this.clickRate = Math.max(this.clickRate - 0.01, 0);
        this.clickRateElement.style.setProperty('--click-rate', this.clickRate.toFixed(2));
      }
    }, 21);
  }

  resetDecreaseInterval() {
    clearInterval(this.decreaseInterval);
    this.startDecreaseClickRate();
  }

  disableClicking() {
    this.hamsterElement.removeEventListener('pointerdown', this.throttledHandlePointerDown);
    clearTimeout(this.inactivityTimeout);
  }

  handleInactivity() {
    this.disableClicking();
    this.callback();
  }

  resetInactivityTimeout() {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(this.handleInactivity, this.inactivityDelay);
  }
}

export default HamsterClicker;
