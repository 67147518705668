class FakeTimer {
  constructor(duration, elementSelector) {
    this.element = document.querySelector(elementSelector);
    this.timeLeft = duration;
    this.countdownInterval = null;
  }

  formatTime(time) {
    return time < 10 ? `0${time}` : `${time}`;
  }

  updateClock() {
    if (this.timeLeft >= 0) {
      this.element.textContent = this.formatTime(this.timeLeft);
      this.timeLeft -= 1;
    } else {
      clearInterval(this.countdownInterval);
    }
  }

  init() {
    this.updateClock();
    this.countdownInterval = setInterval(() => this.updateClock(), 1000);
  }
}

export default FakeTimer;
